import React from 'react';
import classes from './Navbar.module.scss';

const MenuItems = [
    {
        title: "Home", 
        url: "/"
    }, 
    {
        title: "About Us",
        url: "/about"
    }, 
    {
        title: "Process",
        url: "/our-process"
    }, 
    {
        title: "Contact Us",
        url: "/contact"
    },         
    // {
    //     title: "Blog",
    //     url: "/wordpress"
    // },       
    

]

export default MenuItems